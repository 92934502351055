var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.value,
        title: _vm.$t("lbl_more"),
        width: "50%",
        "destroy-on-close": ""
      },
      on: { cancel: _vm.handleClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        "data-testid": "modal-trucking-invoice-discount-cancel"
                      },
                      on: { click: _vm.handleClose }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_cancel")))]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        "data-testid": "modal-trucking-invoice-discount-save",
                        type: "primary"
                      },
                      on: { click: _vm.handleSave }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "a-form-model",
        {
          attrs: {
            "wrapper-col": { span: 12 },
            "label-col": { span: 12 },
            "label-align": "left"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_unit_code") } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "ant-form-text",
                          attrs: {
                            "data-testid":
                              "modal-trucking-invoice-discount-unit-code"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.data.unitCode || "-") + " ")]
                      )
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_serial_number") } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "ant-form-text",
                          attrs: {
                            "data-testid":
                              "modal-trucking-invoice-discount-serial-number"
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.data.serialNumber || "-") + " "
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_equipment") } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "ant-form-text",
                          attrs: {
                            "data-testid":
                              "modal-trucking-invoice-discount-equipment"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.data.equipment || "-") + " ")]
                      )
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_brand") } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "ant-form-text",
                          attrs: {
                            "data-testid":
                              "modal-trucking-invoice-discount-brand"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.data.brand || "-") + " ")]
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_type") } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "ant-form-text",
                          attrs: {
                            "data-testid":
                              "modal-trucking-invoice-discount-type"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.data.type || "-") + " ")]
                      )
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_spec") } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "ant-form-text",
                          attrs: {
                            "data-testid":
                              "modal-trucking-invoice-discount-spec"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.data.spec || "-") + " ")]
                      )
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_base_amount") } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "ant-form-text",
                          attrs: {
                            "data-testid":
                              "modal-trucking-invoice-discount-base-amount"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("currency")(_vm.data.baseAmount)) +
                              " "
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_discount_amount"),
                        prop: "discountAmount"
                      }
                    },
                    [
                      _c("a-input-number", {
                        staticClass: "w-100",
                        attrs: {
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_discount_amount")
                          }),
                          formatter: _vm.formatterNumber,
                          parser: _vm.reverseFormatNumber,
                          min: 0,
                          precision: _vm.storeBaseDecimalPlace,
                          "data-testid":
                            "modal-trucking-invoice-discount-discount-amount"
                        },
                        on: {
                          change: function($event) {
                            return _vm.onChangeDiscount("amount", $event)
                          }
                        },
                        model: {
                          value: _vm.formState.discountAmount,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "discountAmount", $$v)
                          },
                          expression: "formState.discountAmount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_discount_percent"),
                        prop: "discountPercent"
                      }
                    },
                    [
                      _c("a-input-number", {
                        staticClass: "w-100",
                        attrs: {
                          placeholder: _vm.$t("common.insert-text", {
                            text: _vm.$t("lbl_discount_percent")
                          }),
                          formatter: _vm.formatterPercent,
                          parser: _vm.reverseFormatPercent,
                          min: 0,
                          precision: _vm.storeBaseDecimalPlace,
                          "data-testid":
                            "modal-trucking-invoice-discount-discount-percent"
                        },
                        on: {
                          change: function($event) {
                            return _vm.onChangeDiscount("percent", $event)
                          }
                        },
                        model: {
                          value: _vm.formState.discountPercent,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "discountPercent", $$v)
                          },
                          expression: "formState.discountPercent"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }