












































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  buildTruckingAccountReceivableFormState,
  buildTruckingAccountReceivableProductState,
  SearchBuilder
} from "@/builder";
import InvoicePrintModal from "@/components/InvoiceAr/InvoicePrintModal.vue";
import currencyFilter from "@/filters/currency.filter";
import { trimSpaceToUnderscore } from "@/helpers/common";
import { debounceProcess } from "@/helpers/debounce";
import {
  APagination,
  useArPrepayment,
  useBranch,
  useCoa,
  useContactData,
  useCurrency,
  useFindMasterType,
  useInvoiceAR,
  useMapMasterTypeToOptionAlt,
  usePreferences,
  useProduct,
  useRemoveRows,
  useSalesOrder,
  useTax,
  useTaxInvoice,
  useTruckingAccountReceivable
} from "@/hooks";
import { TruckingAccountReceivableMapper } from "@/mapper/TruckingAccountReceivable.mapper";
import MNotification from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  DATE_FORMAT_YYYY_MM_DD,
  DEFAULT_DATE_FORMAT
} from "@/models/constants/date.constant";
import { EnumInvoiceArStatus } from "@/models/enums/invoice.enum";
import { TAX_CALCULATION, TAX_TYPE } from "@/models/enums/tax.enum";
import { InvoicePrepaymentListResponseDto } from "@/models/interface/ar-prepayment";
import {
  AddressDataDto,
  ListContactDataDto
} from "@/models/interface/contact-data";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ProductResponseDto } from "@/models/interface/master-product";
import { AccountingTaxResponseDto } from "@/models/interface/master-tax";
import { SalesOrderResponseDto } from "@/models/interface/sales-order";
import { SalesOrderHeaderData } from "@/models/interface/salesOrder.interface";
import { LabelInValue } from "@/types";
import { RoundingUtils, TruckingAccountReceivableUtils } from "@/utils";
import {
  formatterNumber,
  formatterPercent,
  reverseFormatNumber,
  reverseFormatPercent
} from "@/validator/globalvalidator";
import {
  TruckingAccountReceivableFormDiscountState,
  TruckingAccountReceivableFormPrepaymentLineState,
  TruckingAccountReceivableFormState,
  TruckingAccountReceivableFormStatusDetailInvoiceState,
  TruckingAccountReceivableProductState,
  TruckingInvoiceArCreateDto,
  TruckingInvoiceArUpdateDto
} from "@interface/trucking-account-receivable";
import { FormModel } from "ant-design-vue";
import { Decimal } from "decimal.js-light";
import { Moment } from "moment";
import Vue from "vue";
import { mapState } from "vuex";
import FormTruckingInvoiceStatusView from "./FormTruckingInvoiceStatusView.vue";
import ModalTruckingInvoiceDiscount from "./ModalTruckingInvoiceDiscount.vue";

const NON_STOCKABLE = "Non Stockable";
const SERVICE = "Service";
const SALES_ORDER_TRUCKING = "Trucking";
const INDONESIA_RUPIAH = "IDR";

export default Vue.extend({
  name: "FormTruckingInvoice",
  components: {
    FormTruckingInvoiceStatusView,
    ModalTruckingInvoiceDiscount,
    InvoicePrintModal,
  },
  mixins: [MNotification],
  props: {
    invoiceId: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
    isIdr(): boolean {
      return INDONESIA_RUPIAH === this.formState.currency?.label;
    },
    title() {
      if (this.invoiceId)
        return this.$t("common.edit-text", {
          text: this.$t("lbl_invoice_ar"),
        });
      return this.$t("common.create-text", {
        text: this.$t("lbl_invoice_ar"),
      });
    },
    totalUsedPrepayment(): number {
      return TruckingAccountReceivableUtils.countTotalUsedPrepayment(
        this.formState.prepayment.prepaymentLines
      );
    },
    totalGross(): number {
      return TruckingAccountReceivableUtils.countTotalGross(
        this.formState.products
      );
    },
    totalTax(): number {
      return TruckingAccountReceivableUtils.countTotalTax(
        this.formState.products
      );
    },
    grandTotal(): number {
      // total base amount + total tax
      const totalTax = TruckingAccountReceivableUtils.countTotalTax(
        this.formState.products
      );
      const totalBaseAmount =
        TruckingAccountReceivableUtils.countTotalBaseAmount(
          this.formState.products
        );
      return new Decimal(totalBaseAmount).plus(totalTax).toNumber();
    },
    allowCancel(): boolean {
      const status: Uppercase<EnumInvoiceArStatus>[] = ["UNPAID"];
      return status.includes(
        trimSpaceToUnderscore(
          this.formState.status
        ) as Uppercase<EnumInvoiceArStatus>
      );
    },
    allowPostJournal(): boolean {
      const status: Uppercase<EnumInvoiceArStatus>[] = ["UNPAID"];
      return (
        status.includes(
          trimSpaceToUnderscore(
            this.formState.status
          ) as Uppercase<EnumInvoiceArStatus>
        ) && !this.formState.journalId
      );
    },
    allowPrint(): boolean {
      const status = [
        "NEED_APPROVAL",
        "DELIVERED",
        "REJECTED",
        "CANCELLED",
        "UNPAID",
        "PARTIAL_PAID",
        "FULLY_PAID",
        "RETURNED",
      ];
      return status.includes(trimSpaceToUnderscore(this.formState.status));
    },
    allowUpdate(): boolean {
      const status = ["DRAFT", "NEED_APPROVAL", "DELIVERED", "UNPAID"];
      return (
        status.includes(trimSpaceToUnderscore(this.formState.status)) &&
        !this.formState.journalId
      );
    },
    allowSubmit(): boolean {
      const status: Uppercase<EnumInvoiceArStatus>[] = ["DRAFT"];
      return (
        !this.invoiceId ||
        status.includes(
          trimSpaceToUnderscore(
            this.formState.status
          ) as Uppercase<EnumInvoiceArStatus>
        )
      );
    },
    allowReject(): boolean {
      const status = ["NEED_APPROVAL"];
      return status.includes(trimSpaceToUnderscore(this.formState.status));
    },
  },
  data() {
    this.onSearchBranch = debounceProcess(this.onSearchBranch, 500);
    this.onSearchUnitCode = debounceProcess(this.onSearchUnitCode, 500);
    this.onSearchCustomer = debounceProcess(this.onSearchCustomer, 500);
    this.onSearchCurrency = debounceProcess(this.onSearchCurrency, 500);
    this.onSearchSalesOrder = debounceProcess(this.onSearchSalesOrder, 500);
    this.onSearchAssignee = debounceProcess(this.onSearchAssignee, 500);
    this.onSearchSalesPerson = debounceProcess(this.onSearchSalesPerson, 500);
    this.onSearchTaxCode = debounceProcess(this.onSearchTaxCode, 500);
    this.onSearchIncomeTax = debounceProcess(this.onSearchIncomeTax, 500);
    this.onSearchCustomerTaxType = debounceProcess(
      this.onSearchCustomerTaxType,
      500
    );
    this.onSearchRevenueAccount = debounceProcess(
      this.onSearchRevenueAccount,
      500
    );
    this.onSearchReceivableAccount = debounceProcess(
      this.onSearchReceivableAccount,
      500
    );
    return {
      DEFAULT_DATE_FORMAT,
      PAGE_SIZE_OPTIONS,
      form: this.$refs.form as FormModel,
      formState: buildTruckingAccountReceivableFormState(),
      schemaValidation: {
        invoiceType: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        invoiceSource: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        invoiceDate: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        branch: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        currency: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        customer: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        shippingAddress: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        billingAddress: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        salesOrders: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        taxCalculation: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        termOfPayment: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        receivableAccount: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        assignee: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        description: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        customerTaxType: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        taxInvoiceDate: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
      },
      invoiceTypeOptions: [] as Option[],
      branchOptions: [] as Option[],
      currencyOptions: [] as Option[],
      salesOrderOptions: [] as Option<SalesOrderHeaderData>[],
      customerOptions: [] as Option[],
      customerShippingAddressOptions: [] as Option[],
      customerBillingAddressOptions: [] as Option[],
      taxCalculationOptions: [] as Option[],
      termOfPaymentOptions: [] as Option[],
      receivableAccountOptions: [] as Option[],
      assigneeOptions: [] as Option<ListContactDataDto>[],
      salesPersonOptions: [] as Option[],
      revenueAccountOptions: [] as Option[],
      incomeTaxOptions: [] as Option[],
      taxCodeOptions: [] as Option<AccountingTaxResponseDto>[],
      customerTaxTypeOptions: [] as Option[],
      unitCodeOptions: [] as Option<ProductResponseDto>[],
      selectedRowKeys: [] as string[],
      prepaymentPagination: {
        page: DEFAULT_PAGE,
        limit: DEFAULT_PAGE_SIZE,
      },
      dtPrepayment: {
        currentPage: 0,
        data: [],
        totalElements: 0,
        totalPages: 0,
      } as Pagination<InvoicePrepaymentListResponseDto>,
      loading: {
        invoiceType: false,
        branch: false,
        currency: false,
        salesOrder: false,
        customer: false,
        taxCalculation: false,
        termOfPayment: false,
        receivableAccount: false,
        assignee: false,
        generate: false,
        customerTaxType: false,
        taxInvoiceNumber: false,
        prepayment: false,
        cancel: false,
        postJournal: false,
        update: false,
        submit: false,
        reject: false,
      },
      modalStatus: {
        show: false,
        title: "",
        data: [] as TruckingAccountReceivableFormStatusDetailInvoiceState[],
      },
      modalDiscount: {
        show: false,
        data: {
          unitCode: "",
          serialNumber: "",
          equipment: "",
          brand: "",
          type: "",
          spec: "",
          baseAmount: 0,
          discountAmount: 0,
          discountPercent: 0,
        } as TruckingAccountReceivableFormDiscountState,
      },
      modalPrint: {
        show: false,
      },
      columns: [
        {
          title: this.$t("lbl_document_reference"),
          dataIndex: "docReference",
          scopedSlots: { customRender: "docReference" },
        },
        {
          title: this.$t("lbl_shipment_id"),
          key: "shipmentId",
          scopedSlots: { customRender: "shipmentId" },
        },
        {
          title: this.$t("lbl_unit_code"),
          key: "unitCode",
          scopedSlots: { customRender: "unitCode" },
        },
        {
          title: this.$t("lbl_serial_number"),
          dataIndex: "serialNumber",
          customRender: (text: string) => text ?? "-",
        },
        {
          title: this.$t("lbl_equipment"),
          dataIndex: "equipment",
          customRender: (text: string) => text ?? "-",
        },
        {
          title: this.$t("lbl_brand"),
          dataIndex: "brand",
          customRender: (text: string) => text ?? "-",
        },
        {
          title: this.$t("lbl_type"),
          dataIndex: "type",
          customRender: (text: string) => text ?? "-",
        },
        {
          title: this.$t("lbl_spec"),
          dataIndex: "spec",
          customRender: (text: string) => text ?? "-",
        },
        {
          title: this.$t("lbl_sales_name"),
          key: "salesPerson",
          scopedSlots: { customRender: "salesPerson" },
        },
        {
          title: this.$t("lbl_price"),
          dataIndex: "price",
          scopedSlots: { customRender: "price" },
        },
        {
          title: this.$t("lbl_revenue_account"),
          key: "revenueAccount",
          scopedSlots: { customRender: "revenueAccount" },
        },
        {
          title: this.$t("lbl_base_amount"),
          dataIndex: "baseAmount",
          customRender: (text: number) => currencyFilter(text),
        },
        {
          title: this.$t("lbl_include_pph"),
          key: "includePph",
          scopedSlots: { customRender: "includePph" },
        },
        {
          title: this.$t("lbl_income_tax"),
          key: "incomeTax",
          scopedSlots: { customRender: "incomeTax" },
        },
        {
          title: this.$t("lbl_tax_code"),
          key: "taxCode",
          scopedSlots: { customRender: "taxCode" },
        },
        {
          title: this.$t("lbl_tax_amount"),
          dataIndex: "taxAmount",
          customRender: (text: number) => currencyFilter(text),
        },
        {
          title: this.$t("lbl_sub_total"),
          dataIndex: "subtotal",
          customRender: (text: number) => currencyFilter(text),
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          scopedSlots: { customRender: "description" },
        },
        {
          key: "more",
          scopedSlots: { customRender: "more" },
        },
      ],
      prepaymentColumns: [
        {
          title: this.$t("common.number-text", {
            text: this.$t("lbl_invoice_prepayment"),
          }),
          key: "invoiceNumber",
          scopedSlots: { customRender: "invoiceNumber" },
        },
        {
          title: this.$t("lbl_applied_amount"),
          key: "appliedAmount",
          scopedSlots: { customRender: "appliedAmount" },
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          customRender: (text: string) => text || "-",
        },
        {
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  mounted() {
    this.form = this.$refs.form as FormModel;
    this.getBranchList();
    this.getCustomerList();
    this.getCurrencyList();
    this.getTaxCalculationList();
    this.getTermOfPaymentList();
    this.getAssigneeList();
    this.getSalesPersonList();
    this.getRevenueAccountList();
    this.getIncomeTaxList();
    this.getTaxCodeList();
    this.getCustomerTaxTypeList();
    this.getUnitCodeList();
    this.getInvoiceType();
    if (this.invoiceId) {
      this.getDetail(this.invoiceId);
    } else {
      this.setDefaultCurrency();
      this.setDefaultReceivableAccount();
      this.setDefaultAssignee();
    }
    if (this.formState.currency) {
      this.findReceivableAccount(this.formState.currency.label);
    }
  },
  methods: {
    reverseFormatNumber,
    formatterNumber,
    reverseFormatPercent,
    formatterPercent,
    async getDetail(invoiceId: string) {
      const { findById } = useTruckingAccountReceivable();
      const response = await findById(invoiceId);
      const detailContact = await this.getContactDetail(response.customerId);
      this.setAddressOptions(detailContact.addressDataList);
      this.formState = TruckingAccountReceivableMapper.toFormState(response);
      this.countProductPricing(
        this.formState.products,
        this.formState.taxCalculation,
        this.formState.discountAmount,
        this.totalUsedPrepayment
      );
      this.countTotalFooter(this.formState);
      this.findCustomerPrepayment({
        customerId: this.formState.customer?.key,
        branchId: this.formState.branch?.key,
        currencyCode: this.formState.currency?.label,
      });
      this.findSalesOrders({
        branch: this.formState.branch?.key,
        customer: this.formState.customer?.key,
        currency: this.formState.currency?.label,
      });
      this.findReceivableAccount(response.currency);
    },
    handlePrint() {
      this.modalPrint.show = true;
    },
    async handlePostJournal() {
      const { postJournalInvoiceAr } = useInvoiceAR();
      try {
        this.loading.postJournal = true;
        const response = await postJournalInvoiceAr(this.docId);
        this.showNotifSuccess("notif_update_success", {
          documentNumber: response.journalNo,
        });
        this.$router.push({ name: "trucking.account-receivable.list" });
      } finally {
        this.loading.postJournal = false;
      }
    },
    async handleCancelInvoice() {
      const { cancelInvoiceAr } = useInvoiceAR();
      try {
        this.loading.cancel = true;
        const response = await cancelInvoiceAr(this.invoiceId);
        this.showNotifSuccess("notif_cancel_success", {
          documentNumber: response.documentNumber,
        });
        this.$router.push({ name: "trucking.account-receivable.list" });
      } catch (error) {
        this.showNotifError("notif_cancel_fail");
      } finally {
        this.loading.cancel = false;
      }
    },
    async validateForm(key: "update" | "submit") {
      try {
        await this.form.validate();

        if (key === "update" && this.invoiceId) {
          this.updateInvoiceAr(this.invoiceId, this.formState);
        } else if (key === "submit" && this.invoiceId && this.allowSubmit) {
          this.submitInvoiceAr(this.invoiceId, this.formState);
        } else {
          this.createInvoiceAr(this.formState);
        }
      } catch (error) {
        this.showNotifError("notif_validation_error");
      }
    },
    async createInvoiceAr(state: TruckingAccountReceivableFormState) {
      const { createInvoiceAr: create } = useTruckingAccountReceivable();
      try {
        const request: TruckingInvoiceArCreateDto =
          TruckingAccountReceivableMapper.toInvoiceArCreateDto(state);
        this.loading.submit = true;
        const response = await create(request);
        this.$router.push({ name: "trucking.account-receivable.list" });
        this.showNotifSuccess("notif_create_success", {
          documentNumber: response.documentNumber,
        });
      } catch (error) {
        this.showNotifError("notif_create_fail");
      } finally {
        this.loading.submit = false;
      }
    },
    async submitInvoiceAr(
      invoiceId: string,
      state: TruckingAccountReceivableFormState
    ) {
      const { submitInvoiceAr: submit } = useTruckingAccountReceivable();
      try {
        const request: TruckingInvoiceArUpdateDto =
          TruckingAccountReceivableMapper.toInvoiceArUpdateDto(state);
        this.loading.submit = true;
        const response = await submit(invoiceId, request);
        this.$router.push({ name: "trucking.account-receivable.list" });
        this.showNotifSuccess("notif_submit_success", {
          documentNumber: response.documentNumber,
        });
      } catch (error) {
        this.showNotifError("notif_submit_fail");
      } finally {
        this.loading.submit = false;
      }
    },
    async updateInvoiceAr(
      invoiceId: string,
      state: TruckingAccountReceivableFormState
    ) {
      const { updateInvoiceAr: update } = useTruckingAccountReceivable();
      try {
        const request: TruckingInvoiceArUpdateDto =
          TruckingAccountReceivableMapper.toInvoiceArUpdateDto(state);
        this.loading.update = true;
        const response = await update(invoiceId, request);
        this.$router.push({ name: "trucking.account-receivable.list" });
        this.showNotifSuccess("notif_update_success", {
          documentNumber: response.documentNumber,
        });
      } catch (error) {
        this.showNotifError("notif_update_fail");
      } finally {
        this.loading.update = false;
      }
    },
    async handleRejectInvoice() {
      const { rejectInvoiceAr } = useInvoiceAR();
      try {
        this.loading.reject = true;
        const response = await rejectInvoiceAr(this.invoiceId);
        this.showNotifSuccess("notif_reject_success", {
          documentNumber: response.documentNumber,
        });
        this.$router.push({ name: "trucking.account-receivable.list" });
      } catch (error) {
        this.showNotifError("notif_reject_fail");
      } finally {
        this.loading.reject = false;
      }
    },
    async getInvoiceType() {
      const PREPAYMENT = "PREPAYMENT";
      const CREDIT_MEMO = "CREDIT_MEMO";
      try {
        this.loading.invoiceType = true;
        const response = await useFindMasterType("INVOICE_AR_TYPE");
        const filtered = response.filter(
          ({ value }) =>
            trimSpaceToUnderscore(value) !== PREPAYMENT &&
            trimSpaceToUnderscore(value) !== CREDIT_MEMO
        );
        this.invoiceTypeOptions = filtered.map<Option>(item => ({
          label: item.value,
          value: item.value,
          key: item.value,
        }));

        // new document
        if (!this.invoiceId) {
          this.formState.invoiceType = filtered[0]?.value;
        }
      } finally {
        this.loading.invoiceType = false;
      }
    },
    async getCustomerList(
      params: RequestQueryParamsModel = new RequestQueryParams()
    ) {
      const { findCustomers, toOptionsCustomer } = useContactData();
      try {
        this.loading.customer = true;
        const response = await findCustomers(params);
        this.customerOptions = toOptionsCustomer(response.data);
      } finally {
        this.loading.customer = false;
      }
    },
    onSearchCustomer(value?: string) {
      const { filterBy } = useContactData();
      const params = new RequestQueryParams();
      if (value) {
        params.search = filterBy({ firstName: value, lastName: value });
      }
      this.getCustomerList(params);
    },
    async onChangeCustomer(value: LabelInValue) {
      this.handleResetSalesOrder();
      this.handleResetPrepayment();
      this.formState.customerTaxType = "";
      this.formState.taxRegistrationName = "";
      this.formState.taxRegistrationNumber = "";
      this.formState.termOfPayment = undefined;
      this.formState.shippingAddress = undefined;
      this.formState.billingAddress = undefined;

      // get sales orders
      this.findSalesOrders({
        branch: this.formState.branch?.key,
        currency: this.formState.currency?.label,
        customer: value.key,
      });

      // get customer prepayment
      this.findCustomerPrepayment({
        customerId: value.key,
        branchId: this.formState.branch?.key,
        currencyCode: this.formState.currency?.label,
      });

      try {
        const response = await this.getContactDetail(value.key);
        this.formState.termOfPayment = response.top;

        // set customer tax registration
        if (response.taxRegisName && response.taxRegisNumber) {
          this.setCustomerTaxRegistration(
            response.taxRegisNumber,
            response.taxRegisName,
            response.addressDataList
          );
        }

        // set customer tax type (customer tax invoice code)
        if (response.customerData.taxInvoiceCode) {
          this.formState.customerTaxType = response.customerData.taxInvoiceCode;
          this.setTaxInvoiceNumber(response.customerData.taxInvoiceCode);
        }

        // set customer shipping and billing address
        if (
          response.addressDataList &&
          Array.isArray(response.addressDataList)
        ) {
          this.setDefaultAddress(response.addressDataList);
          this.setAddressOptions(response.addressDataList);
        }
      } catch (error: any) {
        throw new Error(error);
      }
    },
    setAddressOptions(addresses: AddressDataDto[]) {
      const { toBillToAddressOptions, toShipToAddressOptions } =
        useContactData();
      this.customerShippingAddressOptions = toShipToAddressOptions(addresses);
      this.customerBillingAddressOptions = toBillToAddressOptions(addresses);
    },
    setDefaultAddress(addresses: AddressDataDto[]) {
      const { getDefaultShipToAddress, getDefaultBillToAddress } =
        useContactData();
      this.formState.billingAddress = getDefaultBillToAddress(addresses);
      this.formState.shippingAddress = getDefaultShipToAddress(addresses);
    },
    /**
     * @param taxInvoiceCode customer tax invoice code
     * @example 010
     */
    async setTaxInvoiceNumber(taxInvoiceCode: string) {
      if (
        this.formState.taxCalculation === TAX_CALCULATION.NONE ||
        !this.formState.invoiceDate
      ) {
        return;
      }
      const { findInvoiceCode } = useInvoiceAR();
      const params = {
        taxHasBeenUploaded: String(this.formState.taxIsUploaded),
        invoiceDate: this.formState.invoiceDate.format(DATE_FORMAT_YYYY_MM_DD),
        taxInvoiceSerialNumber: this.formState.taxInvoiceNumber,
      };
      try {
        this.formState.taxInvoiceNumber = "";
        this.loading.taxInvoiceNumber = true;
        const response = await findInvoiceCode(taxInvoiceCode, params);
        this.formState.taxInvoiceNumber = response;
      } finally {
        this.loading.taxInvoiceNumber = false;
      }
    },
    setCustomerTaxRegistration(
      taxRegistrationNumber: string,
      taxRegistrationName: string,
      addresses: AddressDataDto[]
    ) {
      if (taxRegistrationNumber === " " || taxRegistrationNumber === "-") {
        const data = addresses.find(
          item => item.idCardNumber && item.idCardNumber !== "-"
        );
        this.formState.taxRegistrationNumber = data?.idCardNumber ?? "";
      } else {
        this.formState.taxRegistrationNumber = taxRegistrationNumber ?? "";
      }

      if (taxRegistrationName !== " " && taxRegistrationName !== "-") {
        this.formState.taxRegistrationName = taxRegistrationName;
      }
    },
    async getContactDetail(customerId: string) {
      const { findOne } = useContactData();
      return findOne(customerId);
    },
    async getBranchList(params?: RequestQueryParamsModel) {
      const { findAll, toOptions } = useBranch();
      try {
        this.loading.branch = true;
        const response = await findAll(params);
        this.branchOptions = toOptions(response.data);
      } finally {
        this.loading.branch = false;
      }
    },
    onSearchBranch(value?: string) {
      const { searchBy } = useBranch();
      const params = new RequestQueryParams();
      if (value) {
        params.search = searchBy({ name: value });
      }
      this.getBranchList(params);
    },
    onChangeBranch(value: LabelInValue) {
      this.handleResetSalesOrder();
      this.handleResetPrepayment();
      this.findCustomerPrepayment({
        customerId: this.formState.customer?.key,
        branchId: value.key,
        currencyCode: this.formState.currency?.label,
      });
    },
    async getCurrencyList(params?: RequestQueryParamsModel) {
      const { findList, toOptionsNew } = useCurrency();
      try {
        this.loading.currency = true;
        const response = await findList(params);
        this.currencyOptions = toOptionsNew(response.data);
      } finally {
        this.loading.currency = false;
      }
    },
    onSearchCurrency(value?: string) {
      const { searchBy } = useCurrency();
      const params = new RequestQueryParams();
      if (value) {
        params.search = searchBy({ code: value });
      }
      this.getCurrencyList(params);
    },
    async onChangeCurrency(value: LabelInValue) {
      this.formState.currencyRates = ONE;
      this.handleResetSalesOrder();
      this.formState.receivableAccount = undefined;
      this.handleResetPrepayment();

      // get currency rates
      if (value) {
        try {
          const rates: number = await this.getCurrencyRates(value.label);
          this.formState.currencyRates = rates;
        } catch {
          this.formState.currencyRates = ONE;
        }
      }

      // get sales order
      if (this.formState.branch && value && this.formState.customer) {
        this.findSalesOrders({
          branch: this.formState.branch.key,
          customer: this.formState.customer.key,
          currency: value.label,
        });

        // get receivable account
        this.getDefaultReceivableAccount(value.label).then(response => {
          if (!response) return;
          this.formState.receivableAccount = {
            key: response.value,
            label: response.name,
          };
        });

        this.findCustomerPrepayment({
          customerId: this.formState.customer?.key,
          branchId: this.formState.branch?.key,
          currencyCode: value.label,
        });
      }
    },
    findSalesOrders(payload: {
      branch: string | undefined;
      customer: string | undefined;
      currency: string | undefined;
      documentNumber?: string;
    }) {
      if (!payload.branch || !payload.customer || !payload.currency) {
        this.formState.salesOrderOptions = [];
        return;
      }
      const params = new RequestQueryParams();
      const searchByBranchAndCustomerAndCurrencyCodeAndSalesType =
        new SearchBuilder()
          .push(["branch.secureId", payload.branch])
          .and()
          .push(["customer.secureId", payload.customer])
          .and()
          .push(["priceCurrency.currencyCode", payload.currency], {
            like: "both",
          })
          .and()
          .push(["salesType", SALES_ORDER_TRUCKING])
          .build();
      const defaultQuery = [
        searchByBranchAndCustomerAndCurrencyCodeAndSalesType,
      ];

      if (payload.documentNumber) {
        const searchByDocumentNumber = new SearchBuilder()
          .push(["documentNumber", payload.documentNumber], { like: "both" })
          .build();
        defaultQuery.push(searchByDocumentNumber);
      }

      params.search = defaultQuery.join(SearchBuilder.AND);
      this.getSalesOrderList(params);
    },
    onSearchSalesOrder(value?: string) {
      this.findSalesOrders({
        branch: this.formState.branch?.key,
        customer: this.formState.customer?.key,
        currency: this.formState.currency?.label,
        documentNumber: value,
      });
    },
    async getSalesOrderList(params?: RequestQueryParamsModel) {
      const { findAvailableSalesOrder } = useSalesOrder();
      try {
        this.loading.salesOrder = true;
        const response = await findAvailableSalesOrder(params);
        this.salesOrderOptions = response.data.map(item => ({
          key: item.id,
          label: item.documentNumber,
          value: item.id,
          meta: item,
        }));
      } finally {
        this.loading.salesOrder = false;
      }
    },
    async getCurrencyRates(currencyCode: string): Promise<number> {
      const { findBaseCurrency } = usePreferences();
      const { findConversion } = useCurrency();
      const baseCurrency = findBaseCurrency();
      if (!baseCurrency) return ONE;
      const conversion = await findConversion(baseCurrency.name, currencyCode);
      const [currencyRates] = conversion.data;
      return currencyRates?.rate ?? ONE;
    },
    async getDefaultReceivableAccount(
      currencyCode: string
    ): Promise<{ value: string; name: string } | null> {
      const { findByKey } = usePreferences();
      const { findAllChildAccount } = useCoa();

      if (currencyCode === INDONESIA_RUPIAH) {
        const response = findByKey("trucking_receivable_account");
        if (response && response.value) {
          return { value: response.value, name: response.name };
        }
      } else {
        const params = new RequestQueryParams();
        params.search = new SearchBuilder()
          .push(["parentAccount.code", "110202"], { like: "end" })
          .and()
          .push(["currency.currencyCode", currencyCode])
          .build();
        const response = await findAllChildAccount(params);
        const [account] = response.data;

        if (!account) return null;

        return {
          value: account.id,
          name: `${account.code} - ${account.description}`,
        };
      }

      return null;
    },
    onDeselectSalesOrder(value: LabelInValue) {
      const products: TruckingAccountReceivableProductState[] = [];
      for (const product of this.formState.products) {
        if (
          product.salesOrderLineId &&
          product.salesOrderLineId === value.key
        ) {
          this.formState.deletedInvoiceARLine.push(value);
        }
        if (product.salesOrderLineId !== value.key) {
          products.push(product);
        }
      }
      this.formState.products = products;

      this.countProductPricing(
        this.formState.products,
        this.formState.taxCalculation,
        this.formState.discountAmount,
        this.totalUsedPrepayment
      );
      this.countTotalFooter(this.formState);
    },
    async getTaxCalculationList() {
      try {
        this.loading.taxCalculation = true;
        const response = await useFindMasterType("TAX_CALCULATION");
        this.taxCalculationOptions = useMapMasterTypeToOptionAlt(response);
      } finally {
        this.loading.taxCalculation = false;
      }
    },
    async getTermOfPaymentList() {
      try {
        this.loading.termOfPayment = true;
        const response = await useFindMasterType("TOP");
        this.termOfPaymentOptions = useMapMasterTypeToOptionAlt(response);
      } finally {
        this.loading.termOfPayment = false;
      }
    },
    async getReceivableAccountList(params?: RequestQueryParamsModel) {
      const { findAllChildAccount, toOptions } = useCoa();
      try {
        this.loading.receivableAccount = true;
        const response = await findAllChildAccount(params);
        this.receivableAccountOptions = toOptions(response.data);
      } catch {
        this.receivableAccountOptions = [];
      } finally {
        this.loading.receivableAccount = false;
      }
    },
    onSearchReceivableAccount(value?: string) {
      if (this.formState.currency) {
        this.findReceivableAccount(this.formState.currency.label, value);
      }
    },
    findReceivableAccount(currencyCode: string, search?: string) {
      const { filterBy } = useCoa();
      const params = new RequestQueryParams();
      const searchByCurrency = new SearchBuilder()
        .push(["parentAccount.code", "110201"], { like: "end" })
        .and()
        .push(["currency.currencyCode", currencyCode], { like: "both" })
        .build();
      const queries: string[] = [searchByCurrency];
      if (search) {
        queries.unshift(filterBy({ code: search, description: search }));
      }
      params.search = queries.join(SearchBuilder.AND);
      this.getReceivableAccountList(params);
    },
    setDefaultCurrency() {
      const { findBaseCurrency } = usePreferences();
      const response = findBaseCurrency();
      if (response && response.value) {
        this.formState.currency = {
          key: response.value,
          label: response.name,
        };
      }
    },
    async setDefaultReceivableAccount() {
      if (!this.formState.currency) return;

      try {
        const response = await this.getDefaultReceivableAccount(
          this.formState.currency.label
        );
        if (!response) return;

        this.formState.receivableAccount = {
          key: response.value,
          label: response.name,
        };
      } catch (error) {
        this.formState.receivableAccount = undefined;
      }
    },
    async getAssigneeList(
      params: RequestQueryParamsModel = new RequestQueryParams()
    ) {
      const { findAllEmployee } = useContactData();
      try {
        this.loading.assignee = true;
        const response = await findAllEmployee(params);
        this.assigneeOptions = response.data.map(item => ({
          label: item.employeePosition,
          key: item.id,
          value: item.id,
          meta: item,
        }));
      } finally {
        this.loading.assignee = false;
      }
    },
    onSearchAssignee(value?: string) {
      const { filterBy } = useContactData();
      const params = new RequestQueryParams();
      if (value) {
        params.search = filterBy({ firstName: value, lastName: value });
      }
      this.getAssigneeList(params);
    },
    setDefaultAssignee() {
      const { findByKey } = usePreferences();
      const response = findByKey("trucking_assignee");
      if (response && response.value) {
        this.formState.assignee = {
          label: response.name,
          key: response.value,
        };
      }
    },
    async handleGenerate() {
      const { findById } = useSalesOrder();
      const salesOrders: Promise<SalesOrderResponseDto>[] =
        this.formState.salesOrders.map((item: LabelInValue) =>
          findById(item.key)
        );
      try {
        this.loading.generate = true;
        const response = await Promise.all(salesOrders);
        const taxTypes = new Set();

        for (const doc of response) {
          taxTypes.add(doc.taxCalculation);
        }

        if (taxTypes.size > ONE) {
          this.showNotifWarning("notif_document_has_different_tax_type");
          return;
        }

        for (const detail of response) {
          const products: TruckingAccountReceivableProductState[] =
            TruckingAccountReceivableMapper.toInvoiceProductsState(detail);
          this.formState.products.push(...products);
        }
        this.countProductPricing(
          this.formState.products,
          this.formState.taxCalculation,
          this.formState.discountAmount,
          this.totalUsedPrepayment
        );
        this.countTotalFooter(this.formState);
      } finally {
        this.loading.generate = false;
      }
    },
    async fetchSalesPersonList(
      params: RequestQueryParamsModel = new RequestQueryParams()
    ) {
      const { findSales, toOptions } = useContactData();
      try {
        const response = await findSales(params);
        return toOptions(response.data);
      } catch (error) {
        return [];
      }
    },
    async getSalesPersonList() {
      this.salesPersonOptions = await this.fetchSalesPersonList();
    },
    async onSearchSalesPerson(
      record: TruckingAccountReceivableProductState,
      value?: string
    ) {
      const { filterBy } = useContactData();
      record.isSearchSalesPerson = true;
      const params = new RequestQueryParams();
      if (value) {
        params.search = filterBy({ firstName: value, lastName: value });
      }
      record.loadingSalesPerson = true;
      record.salesPersonOptions = await this.fetchSalesPersonList(params);
      record.loadingSalesPerson = false;
    },
    async fetchRevenueAccount(
      params: RequestQueryParamsModel = new RequestQueryParams()
    ) {
      const { findAllChildAccount, toOptions } = useCoa();
      const ACCOUNT_TYPE = "Pendapatan";
      const defaultQueries: string[] = [
        new SearchBuilder()
          .push(["accountType.name", ACCOUNT_TYPE], { like: "both" })
          .build(),
      ];
      if (params.search) {
        defaultQueries.push(params.search);
      }
      params.search = defaultQueries.join(SearchBuilder.AND);
      try {
        const response = await findAllChildAccount(params);
        return toOptions(response.data);
      } catch (error) {
        return [];
      }
    },
    async getRevenueAccountList() {
      this.revenueAccountOptions = await this.fetchRevenueAccount();
    },
    async onSearchRevenueAccount(
      record: TruckingAccountReceivableProductState,
      value?: string
    ) {
      const { filterBy } = useCoa();
      record.isSearchRevenueAccount = true;
      const params = new RequestQueryParams();
      if (value) {
        params.search = filterBy({ code: value, description: value });
      }
      record.loadingRevenueAccount = true;
      record.revenueAccountOptions = await this.fetchRevenueAccount(params);
      record.loadingRevenueAccount = false;
    },
    async fetchIncomeTax(
      params: RequestQueryParamsModel = new RequestQueryParams()
    ) {
      const { findCollections, toOptions } = useTax();
      const queries: string[] = [
        new SearchBuilder()
          .push([
            "taxType",
            trimSpaceToUnderscore(TAX_TYPE.INCOME_TAX_RECEIVABLES),
          ])
          .build(),
      ];
      if (params?.search) {
        queries.unshift(params.search);
      }
      params.search = queries.join(SearchBuilder.AND);
      params.sorts = "createdDate:desc";
      try {
        const response = await findCollections(params);
        return toOptions(response.data);
      } catch (errror) {
        return [];
      }
    },
    async getIncomeTaxList() {
      this.incomeTaxOptions = await this.fetchIncomeTax();
    },
    async onSearchIncomeTax(
      record: TruckingAccountReceivableProductState,
      value?: string
    ) {
      const { filterBy } = useTax();
      record.isSearchIncomeTax = true;
      const params = new RequestQueryParams();
      if (value) {
        params.search = filterBy({ code: value, description: value });
      }
      try {
        record.loadingIncomeTax = true;
        record.incomeTaxOptions = await this.fetchIncomeTax(params);
      } finally {
        record.loadingIncomeTax = false;
      }
    },
    async fetchTaxCode(search?: string) {
      const { findAllVatOut, toOptions } = useTax();
      try {
        const response = await findAllVatOut(search);
        return toOptions(response.data);
      } catch (error) {
        return [];
      }
    },
    async getTaxCodeList() {
      this.taxCodeOptions = await this.fetchTaxCode();
    },
    async onSearchTaxCode(
      record: TruckingAccountReceivableProductState,
      value?: string
    ) {
      record.isSearchTaxCode = true;
      record.loadingTaxCode = true;
      record.taxCodeOptions = await this.fetchTaxCode(value);
      record.loadingTaxCode = false;
    },
    onChangePrice() {
      this.countProductPricing(
        this.formState.products,
        this.formState.taxCalculation,
        this.formState.discountAmount,
        this.totalUsedPrepayment
      );
      this.countTotalFooter(this.formState);
    },
    onChangeTaxCode(
      record: TruckingAccountReceivableProductState,
      value: LabelInValue
    ) {
      const options: Option<AccountingTaxResponseDto>[] = record.isSearchTaxCode
        ? record.taxCodeOptions
        : this.taxCodeOptions;
      const tax = options.find(item => item.value === value.key);
      record.taxRate = 0;
      if (tax) {
        record.taxRate = tax.meta?.rate ?? 0;
      }

      this.countProductPricing(
        this.formState.products,
        this.formState.taxCalculation,
        this.formState.discountAmount,
        this.totalUsedPrepayment
      );
      this.countTotalFooter(this.formState);
    },
    onChangeIncludePph(record: TruckingAccountReceivableProductState) {
      record.incomeTax = undefined;
    },
    setDefaultRevenueAccount(record: TruckingAccountReceivableProductState) {
      TruckingAccountReceivableUtils.setDefaultRevenueAccount(record);
    },
    setDefaultTaxCode(record: TruckingAccountReceivableProductState) {
      TruckingAccountReceivableUtils.setDefaultTaxCode(record);
    },
    onSelectChange(values: string[]) {
      this.selectedRowKeys = values;
    },
    addRow() {
      const newProduct: TruckingAccountReceivableProductState =
        buildTruckingAccountReceivableProductState();
      this.setDefaultRevenueAccount(newProduct);
      this.setDefaultTaxCode(newProduct);
      this.formState.products.push(newProduct);
    },
    deleteRow() {
      const { newSource, deletedRows } =
        useRemoveRows<TruckingAccountReceivableProductState>(
          this.formState.products,
          this.selectedRowKeys
        );
      this.selectedRowKeys = [];
      this.formState.products = [...newSource];
      deletedRows.forEach(item => {
        if (item.id) {
          this.formState.deletedInvoiceARLines.push(item);
        }
      });

      this.updateSalesOrderRelation();
      this.countProductPricing(
        this.formState.products,
        this.formState.taxCalculation,
        this.formState.discountAmount,
        this.totalUsedPrepayment
      );
      this.countTotalFooter(this.formState);
    },
    updateSalesOrderRelation() {
      // remove the sales order that we dont need
      // collect all sales orders reference from all products
      const salesOrderIds: Set<string> = new Set();
      for (const item of this.formState.products) {
        if (item.docReferenceId) {
          salesOrderIds.add(item.docReferenceId);
        }
      }
      // get all used sales orders
      const salesOrders: LabelInValue[] = [];
      for (const doc of this.formState.salesOrders) {
        if (salesOrderIds.has(doc.key)) {
          salesOrders.push(doc);
        }
      }

      this.formState.salesOrders = [...salesOrders];
    },
    async getCustomerTaxTypeList(params = new RequestQueryParams()) {
      const { findAll, toOptions } = useTaxInvoice();
      const defaultQuery: string[] = [
        new SearchBuilder().push(["revisionCode", "false"]).build(),
      ];
      if (params.search) {
        defaultQuery.push(params.search);
      }
      params.search = defaultQuery.join(SearchBuilder.AND);
      try {
        this.loading.customerTaxType = true;
        const response = await findAll(params);
        this.customerTaxTypeOptions = toOptions(response.data);
      } finally {
        this.loading.customerTaxType = false;
      }
    },
    onSearchCustomerTaxType(value?: string) {
      const { findAllBy } = useTaxInvoice();
      const params = new RequestQueryParams();
      if (value) {
        params.search = findAllBy({
          code: value,
        }).search;
      }
      this.getCustomerTaxTypeList(params);
    },
    onChangeCustomerTaxType(value: string) {
      this.formState.taxInvoiceNumber = "";
      if (this.formState.invoiceDate) {
        this.setTaxInvoiceNumber(value);
      }
    },
    onChangeTaxUpload() {
      this.setTaxInvoiceNumber(this.formState.customerTaxType);
    },
    onChangeInvoiceDate(value: Moment | null) {
      this.formState.accountingDate = value;
      this.formState.taxInvoiceDate = value;
    },
    toggleDisableProductTax(state: boolean) {
      this.formState.products.forEach(
        (item: TruckingAccountReceivableProductState) => {
          item.disabledTax = state;
          if (state) {
            item.incomeTax = undefined;
            item.includePph = false;
            item.taxCode = undefined;
            item.taxAmount = 0;
            item.taxRate = 0;
          }
        }
      );
    },
    onChangeTaxCalculation(value: TAX_CALCULATION) {
      if (value === TAX_CALCULATION.NONE) {
        this.formState.customerTaxType = "";
        this.formState.taxIsUploaded = false;
        this.formState.taxInvoiceDate = null;
        this.formState.taxInvoiceNumber = "";
        this.toggleDisableProductTax(true);
      } else {
        this.toggleDisableProductTax(false);
      }

      this.countProductPricing(
        this.formState.products,
        this.formState.taxCalculation,
        this.formState.discountAmount,
        this.totalUsedPrepayment
      );
      this.countTotalFooter(this.formState);
    },
    handleResetPrepayment() {
      this.formState.prepayment.prepaymentLines.forEach(
        (item: TruckingAccountReceivableFormPrepaymentLineState) => {
          if (item.id) {
            this.formState.prepayment.deletedPrepaymentLineIds.push(item.id);
          }
        }
      );
      this.formState.prepayment.prepaymentLines = [];
      this.countProductPricing(
        this.formState.products,
        this.formState.taxCalculation,
        this.formState.discountAmount,
        this.totalUsedPrepayment
      );
      this.countTotalFooter(this.formState);
    },
    handleUsePrepayment(record: InvoicePrepaymentListResponseDto) {
      const prepayment: TruckingAccountReceivableFormPrepaymentLineState =
        TruckingAccountReceivableMapper.toPrepaymentLineState(record);
      this.formState.prepayment.prepaymentLines.push(prepayment);

      this.countProductPricing(
        this.formState.products,
        this.formState.taxCalculation,
        this.formState.discountAmount,
        this.totalUsedPrepayment
      );
      this.countTotalFooter(this.formState);
    },
    isPrepaymentUsed(record: InvoicePrepaymentListResponseDto): boolean {
      for (const prepayment of this.formState.prepayment.prepaymentLines) {
        if (prepayment.invoicePrepaymentId === record.id) {
          return true;
        }
      }

      return false;
    },
    onChangePrepaymentTable(pagination: APagination) {
      const { current, pageSize } = pagination;
      this.prepaymentPagination.page = current;
      if (pageSize !== this.prepaymentPagination.limit) {
        this.prepaymentPagination.page = DEFAULT_PAGE;
      }
      this.prepaymentPagination.limit = pageSize;
      this.findCustomerPrepayment({
        customerId: this.formState.customer?.key,
        branchId: this.formState.branch?.key,
        currencyCode: this.formState.currency?.label,
        pagination: this.prepaymentPagination,
      });
    },
    async getCustomerPrepaymentList(params?: RequestQueryParamsModel) {
      const { findAll } = useArPrepayment();
      this.loading.prepayment = true;
      const response = await findAll(params);
      this.dtPrepayment = response;
      this.loading.prepayment = false;
    },
    findCustomerPrepayment(data: {
      customerId: string | undefined;
      branchId: string | undefined;
      currencyCode: string | undefined;
      pagination?: { page: number; limit: number };
    }) {
      if (!data.customerId || !data.branchId || !data.currencyCode) {
        this.dtPrepayment.currentPage = 0;
        this.dtPrepayment.data = [];
        this.dtPrepayment.totalElements = 0;
        this.dtPrepayment.totalPages = 0;
        return;
      }
      const params = new RequestQueryParams();
      if (data.pagination) {
        params.limit = data.pagination.limit;
        params.page = data.pagination.page;
      }
      params.search = new SearchBuilder()
        .push(["customer.secureId", data.customerId])
        .and()
        .push(["branchWarehouse.secureId", data.branchId])
        .and()
        .push(["priceCurrency.currencyCode", data.currencyCode])
        .build();

      this.getCustomerPrepaymentList(params);
    },
    handleResetSalesOrder() {
      this.formState.salesOrders = [];
      this.handleResetProducts();
    },
    handleResetProducts() {
      for (const product of this.formState.products) {
        if (product.id) {
          this.formState.deletedInvoiceARLines.push(product.id);
        }
      }
      this.formState.products = [];
    },
    handleDeleteUsedPrepayment(
      record: TruckingAccountReceivableFormPrepaymentLineState
    ) {
      if (record.id) {
        this.formState.prepayment.deletedPrepaymentLineIds.push(record.id);
      }
      const removeIndex = this.formState.prepayment.prepaymentLines.findIndex(
        (item: TruckingAccountReceivableFormPrepaymentLineState) =>
          item.invoicePrepaymentId === record.invoicePrepaymentId
      );
      if (removeIndex >= 0) {
        this.formState.prepayment.prepaymentLines.splice(removeIndex, 1);
      }
      this.countProductPricing(
        this.formState.products,
        this.formState.taxCalculation,
        this.formState.discountAmount,
        this.totalUsedPrepayment
      );
      this.countTotalFooter(this.formState);
    },
    handleRefreshPrepayment() {
      this.findCustomerPrepayment({
        customerId: this.formState.customer?.key,
        branchId: this.formState.branch?.key,
        currencyCode: this.formState.currency?.label,
      });
    },
    handleStatusDetailView(key: "prepayment" | "creditMemo" | "paidStatus") {
      let data: TruckingAccountReceivableFormStatusDetailInvoiceState[] = [];
      let title = "";
      if (key === "prepayment") {
        data = this.formState.statusDetail.prepaymentLines;
        title = this.$t("lbl_prepayment_used").toString();
      } else if (key === "creditMemo") {
        data = this.formState.statusDetail.creditMemoLines;
        title = this.$t("lbl_credit_memo").toString();
      } else if (key === "paidStatus") {
        data = this.formState.statusDetail.invoiceReceipts;
        title = this.$t("lbl_paid_status").toString();
      }
      this.modalStatus.title = title;
      this.modalStatus.show = true;
      this.modalStatus.data = data;
    },
    handleCloseInvoiceStatusModal() {
      this.modalStatus.show = false;
      this.modalStatus.title = "";
      this.modalStatus.data = [];
    },
    handleViewDiscount(record: TruckingAccountReceivableProductState) {
      this.modalDiscount.show = true;
      const data: TruckingAccountReceivableFormDiscountState = {
        key: record.key,
        unitCode: record.unitCode?.label ?? "",
        serialNumber: record.serialNumber,
        equipment: record.equipment,
        brand: record.brand,
        type: record.type,
        spec: record.spec,
        baseAmount: record.baseAmount,
        discountAmount: record.discountAmount,
        discountPercent: record.discountPercent,
        gross: new Decimal(record.price ?? 0)
          .times(record.quantity ?? 0)
          .toNumber(),
      };
      this.modalDiscount.data = { ...data };
    },
    handleAddDiscount({
      formState,
      key,
    }: {
      formState: { discountAmount: number; discountPercent: number };
      key: string;
    }) {
      const record: TruckingAccountReceivableProductState | undefined =
        this.formState.products.find(
          (product: TruckingAccountReceivableProductState) => {
            return product.key === key;
          }
        );
      if (record) {
        record.discountAmount = formState.discountAmount ?? 0;
        record.discountPercent = formState.discountPercent ?? 0;
      }

      this.countProductPricing(
        this.formState.products,
        this.formState.taxCalculation,
        this.formState.discountAmount,
        this.totalUsedPrepayment
      );
      this.countTotalFooter(this.formState);
    },
    onChangeAdditionalDiscount(value: number | null, isPercent = false) {
      this.formState.discountAmount = 0;
      this.formState.discountPercent = 0;
      if (value) {
        const totalGross = TruckingAccountReceivableUtils.countTotalGross(
          this.formState.products
        );
        const { amount, percent } =
          TruckingAccountReceivableUtils.countDiscount(
            value,
            totalGross,
            isPercent
          );
        this.formState.discountAmount = amount;
        this.formState.discountPercent = percent;
      }
      this.countProductPricing(
        this.formState.products,
        this.formState.taxCalculation,
        this.formState.discountAmount,
        this.totalUsedPrepayment
      );
      this.countTotalFooter(this.formState);
    },
    countProductPricing(
      products: TruckingAccountReceivableProductState[],
      taxCalculation: TAX_CALCULATION,
      additionalDiscount = 0,
      totalPrepaymentUsed = 0
    ) {
      products.forEach((product: TruckingAccountReceivableProductState) => {
        let gross: number = TruckingAccountReceivableUtils.countGross(product);
        if (taxCalculation === TAX_CALCULATION.INCLUSIVE) {
          const divider = new Decimal(product.taxRate).dividedBy(100).plus(ONE);
          gross = RoundingUtils.round(
            new Decimal(gross).dividedBy(divider),
            Decimal.ROUND_HALF_EVEN
          ).toNumber();
        }

        const grossAfterDiscount = new Decimal(gross).minus(
          product.discountAmount || 0
        );
        product.grossAfterDiscount = grossAfterDiscount.toNumber();
      });

      const sumGrossAfterDiscount = products.reduce((curr, value) => {
        return new Decimal(value.grossAfterDiscount || 0).plus(curr).toNumber();
      }, 0);

      products.forEach((product: TruckingAccountReceivableProductState) => {
        let proRateRatio = new Decimal(0);
        proRateRatio = RoundingUtils.round14(
          new Decimal(product.grossAfterDiscount || 0).dividedBy(
            sumGrossAfterDiscount
          )
        );

        product.proRateRatio = proRateRatio.toNumber();

        const additionalDiscountAmount = new Decimal(additionalDiscount || 0);
        const proRateAdditionalDiscountAmount = RoundingUtils.round14(
          additionalDiscountAmount.times(product.proRateRatio)
        );
        product.proRateAdditionalDiscountAmount =
          proRateAdditionalDiscountAmount.toNumber();

        const amountAfterProRateAdditionalDiscount = new Decimal(
          product.grossAfterDiscount || 0
        ).minus(product.proRateAdditionalDiscountAmount || 0);
        product.amountAfterProRateAdditionalDiscount =
          amountAfterProRateAdditionalDiscount.toNumber();
      });

      const sumAmountAfterDiscountInvoice: number = products.reduce(
        (curr, value) => {
          return new Decimal(value.amountAfterProRateAdditionalDiscount || 0)
            .plus(curr)
            .toNumber();
        },
        0
      );

      const sumTotalAppliedPrepayment: number = totalPrepaymentUsed;

      products.forEach(product => {
        const prepaymentRatio: number = RoundingUtils.round14(
          new Decimal(
            product.amountAfterProRateAdditionalDiscount || 0
          ).dividedBy(sumAmountAfterDiscountInvoice)
        ).toNumber();
        product.proRateAmountAfterAdditionalDiscountRatio = prepaymentRatio;

        const prepaymentLineAmount: number = new Decimal(
          product.proRateAmountAfterAdditionalDiscountRatio
        )
          .times(sumTotalAppliedPrepayment)
          .toNumber();
        product.proRatePrepaymentAmount = prepaymentLineAmount;

        let baseAmount: Decimal;
        let taxAmount = new Decimal(0);
        let subTotal: Decimal;

        baseAmount = new Decimal(
          product.amountAfterProRateAdditionalDiscount || 0
        ).minus(product.proRatePrepaymentAmount);

        if (taxCalculation === TAX_CALCULATION.EXCLUSIVE) {
          taxAmount = RoundingUtils.round(
            baseAmount.times(product.taxRate || 0).dividedBy(100),
            Decimal.ROUND_HALF_EVEN
          );
          subTotal = baseAmount.plus(taxAmount || 0);
          product.baseAmount = RoundingUtils.round(
            baseAmount,
            Decimal.ROUND_HALF_EVEN
          ).toNumber();
          product.taxAmount = RoundingUtils.round(
            taxAmount,
            Decimal.ROUND_HALF_EVEN
          ).toNumber();
          product.subtotal = RoundingUtils.round(
            subTotal,
            Decimal.ROUND_HALF_EVEN
          ).toNumber();
        } else if (taxCalculation === TAX_CALCULATION.INCLUSIVE) {
          const grossValue = new Decimal(product.quantity).times(
            product.price || 0
          );
          const baseAmountInclusive = grossValue.dividedBy(
            new Decimal(product.taxRate || 0).dividedBy(100).plus(1)
          );

          const baseAmountAfterDiscountLine = baseAmountInclusive.minus(
            product.discountAmount
          );

          // additional discount invoice amount
          const additionalDiscountAmount = new Decimal(additionalDiscount || 0);
          /**
           * find amount of aditional discount amount after pro rate
           * on each line
           * formula = pro rate additional discount X additional discount invoice
           */
          const discountInvoiceValue = new Decimal(product.proRateRatio).times(
            additionalDiscountAmount
          );
          const prepaymentValue = new Decimal(
            product.proRateAmountAfterAdditionalDiscountRatio
          ).times(sumTotalAppliedPrepayment);

          baseAmount = baseAmountAfterDiscountLine
            .minus(prepaymentValue)
            .minus(discountInvoiceValue);

          taxAmount = RoundingUtils.round(
            baseAmount.times(product.taxRate).dividedBy(100),
            Decimal.ROUND_HALF_EVEN
          );

          subTotal = baseAmount.plus(taxAmount);
          product.baseAmount = RoundingUtils.round(
            baseAmount,
            Decimal.ROUND_HALF_EVEN
          ).toNumber();
          product.taxAmount = RoundingUtils.round(
            taxAmount,
            Decimal.ROUND_HALF_EVEN
          ).toNumber();
          product.subtotal = RoundingUtils.round(
            subTotal,
            Decimal.ROUND_HALF_EVEN
          ).toNumber();
        } else {
          subTotal = baseAmount;
          product.baseAmount = RoundingUtils.round(
            baseAmount,
            Decimal.ROUND_HALF_EVEN
          ).toNumber();
          product.taxAmount = RoundingUtils.round(
            taxAmount,
            Decimal.ROUND_HALF_EVEN
          ).toNumber();
          product.subtotal = RoundingUtils.round(
            subTotal,
            Decimal.ROUND_HALF_EVEN
          ).toNumber();
        }
      });
    },
    countTotalFooter(state: TruckingAccountReceivableFormState) {
      state.totalGross = this.totalGross as number;
      state.totalTax = this.totalTax as number;
      state.totalUsedPrepayment = this.totalUsedPrepayment as number;
      state.grandTotal = this.grandTotal as number;
    },
    handleBack() {
      this.showConfirmationLeavePage(() => {
        this.$router.push({ name: "trucking.account-receivable.list" });
      });
    },
    async fetchUnitCodeList(params?: RequestQueryParamsModel) {
      const { findAll, toOptions } = useProduct();
      try {
        const response = await findAll(params);
        return toOptions(response.data);
      } catch (error) {
        return [];
      }
    },
    async getUnitCodeList() {
      const params = new RequestQueryParams();
      params.search = new SearchBuilder()
        .push(["type", NON_STOCKABLE])
        .or()
        .push(["type", SERVICE])
        .build();
      this.unitCodeOptions = await this.fetchUnitCodeList(params);
    },
    async onSearchUnitCode(
      record: TruckingAccountReceivableProductState,
      value?: string
    ) {
      record.isSearchUnitCode = true;
      record.loadingUnitCode = true;
      const params = new RequestQueryParams();
      if (value) {
        params.search = new SearchBuilder()
          .push(["description", value])
          .or()
          .push(["code", value])
          .and()
          .push(["type", NON_STOCKABLE])
          .or()
          .push(["type", SERVICE])
          .build();
      }
      const response = await this.fetchUnitCodeList(params);
      record.unitCodeOptions = response;
      record.loadingUnitCode = false;
    },
  },
});
