var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.title } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                model: _vm.formState,
                rules: _vm.schemaValidation,
                "wrapper-col": { span: 15 },
                "label-col": { span: 6 },
                "label-align": "left"
              }
            },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.formState.invoiceNumber
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_invoice_number"),
                                prop: "invoiceNumber"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ant-form-text",
                                  attrs: {
                                    "data-testid":
                                      "trucking-ar-invoice-invoice-number"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.formState.invoiceNumber))]
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_invoice_type"),
                            prop: "invoiceType"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              options: _vm.invoiceTypeOptions,
                              loading: _vm.loading.invoiceType,
                              "data-testid": "trucking-ar-invoice-type"
                            },
                            model: {
                              value: _vm.formState.invoiceType,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "invoiceType", $$v)
                              },
                              expression: "formState.invoiceType"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_invoice_date"),
                            prop: "invoiceDate"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              format: _vm.DEFAULT_DATE_FORMAT,
                              "data-testid": "trucking-ar-invoice-date",
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_invoice_date")
                              })
                            },
                            on: { change: _vm.onChangeInvoiceDate },
                            model: {
                              value: _vm.formState.invoiceDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "invoiceDate", $$v)
                              },
                              expression: "formState.invoiceDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t("lbl_branch"), prop: "branch" }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              "show-search": "",
                              loading: _vm.loading.branch,
                              "data-testid": "trucking-ar-invoice-branch",
                              "filter-option": false,
                              options: _vm.branchOptions,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_branch")
                              }),
                              "label-in-value": ""
                            },
                            on: {
                              search: _vm.onSearchBranch,
                              change: _vm.onChangeBranch
                            },
                            model: {
                              value: _vm.formState.branch,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "branch", $$v)
                              },
                              expression: "formState.branch"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_currency"),
                            prop: "currency"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              "show-search": "",
                              loading: _vm.loading.currency,
                              "data-testid": "trucking-ar-invoice-currency",
                              "filter-option": false,
                              options: _vm.currencyOptions,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_currency")
                              }),
                              "label-in-value": ""
                            },
                            on: {
                              search: _vm.onSearchCurrency,
                              change: _vm.onChangeCurrency
                            },
                            model: {
                              value: _vm.formState.currency,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "currency", $$v)
                              },
                              expression: "formState.currency"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isIdr,
                              expression: "!isIdr"
                            }
                          ],
                          attrs: {
                            label: _vm.$t("lbl_currency_rates"),
                            prop: "currencyRates"
                          }
                        },
                        [
                          _c("a-input-number", {
                            staticClass: "w-100",
                            attrs: {
                              placeholder: _vm.$t("common.insert-text", {
                                text: _vm.$t("lbl_currency_rates")
                              }),
                              parser: _vm.reverseFormatNumber,
                              formatter: _vm.formatterNumber,
                              min: 0,
                              precision: _vm.storeBaseDecimalPlace,
                              "data-testid":
                                "trucking-ar-invoice-currency-rates"
                            },
                            model: {
                              value: _vm.formState.currencyRates,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "currencyRates", $$v)
                              },
                              expression: "formState.currencyRates"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer"),
                            prop: "customer"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              "show-search": "",
                              loading: _vm.loading.customer,
                              "data-testid": "trucking-ar-invoice-customer",
                              "filter-option": false,
                              options: _vm.customerOptions,
                              "dropdown-match-select-width": false,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_customer")
                              }),
                              "label-in-value": ""
                            },
                            on: {
                              search: _vm.onSearchCustomer,
                              change: _vm.onChangeCustomer
                            },
                            model: {
                              value: _vm.formState.customer,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "customer", $$v)
                              },
                              expression: "formState.customer"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_ship_address"),
                            prop: "shippingAddress"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              "data-testid":
                                "trucking-ar-invoice-shipping-address",
                              "dropdown-match-select-width": false,
                              "filter-option": false,
                              options: _vm.customerShippingAddressOptions,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_customer_ship_address")
                              })
                            },
                            model: {
                              value: _vm.formState.shippingAddress,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "shippingAddress", $$v)
                              },
                              expression: "formState.shippingAddress"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_bill_address"),
                            prop: "billingAddress"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              "data-testid":
                                "trucking-ar-invoice-billing-address",
                              "dropdown-match-select-width": false,
                              "filter-option": false,
                              options: _vm.customerBillingAddressOptions,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_customer_bill_address")
                              })
                            },
                            model: {
                              value: _vm.formState.billingAddress,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "billingAddress", $$v)
                              },
                              expression: "formState.billingAddress"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        { attrs: { type: "flex", gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_sales_order"),
                                    prop: "salesOrders"
                                  }
                                },
                                [
                                  _c("a-select", {
                                    staticClass: "w-100",
                                    attrs: {
                                      "show-search": "",
                                      loading: _vm.loading.salesOrder,
                                      "data-testid":
                                        "trucking-ar-invoice-sales-order",
                                      "filter-option": false,
                                      options: _vm.salesOrderOptions,
                                      placeholder: _vm.$t(
                                        "common.select-text",
                                        { text: _vm.$t("lbl_sales_order") }
                                      ),
                                      "label-in-value": "",
                                      mode: "multiple"
                                    },
                                    on: {
                                      search: _vm.onSearchSalesOrder,
                                      deselect: _vm.onDeselectSalesOrder
                                    },
                                    model: {
                                      value: _vm.formState.salesOrders,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formState,
                                          "salesOrders",
                                          $$v
                                        )
                                      },
                                      expression: "formState.salesOrders"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    "html-type": "button",
                                    type: "primary",
                                    loading: _vm.loading.generate,
                                    "data-testid":
                                      "trucking-ar-invoice-generate"
                                  },
                                  on: { click: _vm.handleGenerate }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_generate")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_tax_calculation"),
                            prop: "taxCalculation"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              loading: _vm.loading.taxCalculation,
                              "data-testid":
                                "trucking-ar-invoice-tax-calculation",
                              options: _vm.taxCalculationOptions,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_tax_calculation")
                              })
                            },
                            on: { change: _vm.onChangeTaxCalculation },
                            model: {
                              value: _vm.formState.taxCalculation,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "taxCalculation", $$v)
                              },
                              expression: "formState.taxCalculation"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_term_of_payment"),
                            prop: "termOfPayment"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              loading: _vm.loading.termOfPayment,
                              "data-testid":
                                "trucking-ar-invoice-term-of-payment",
                              options: _vm.termOfPaymentOptions,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_term_of_payment")
                              })
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "suffixIcon",
                                fn: function() {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$tc(
                                              "common.day",
                                              _vm.formState.termOfPayment
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.formState.termOfPayment,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "termOfPayment", $$v)
                              },
                              expression: "formState.termOfPayment"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_accounting_date"),
                            prop: "accountingDate"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              format: _vm.DEFAULT_DATE_FORMAT,
                              "data-testid":
                                "trucking-ar-invoice-accounting-date",
                              disabled: ""
                            },
                            model: {
                              value: _vm.formState.accountingDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "accountingDate", $$v)
                              },
                              expression: "formState.accountingDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_receivable_account"),
                            prop: "receivableAccount"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              "show-search": "",
                              loading: _vm.loading.receivableAccount,
                              "data-testid":
                                "trucking-ar-invoice-receivable-account",
                              "filter-option": false,
                              options: _vm.receivableAccountOptions,
                              "dropdown-match-select-width": false,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_receivable_account")
                              }),
                              "label-in-value": ""
                            },
                            on: { search: _vm.onSearchReceivableAccount },
                            model: {
                              value: _vm.formState.receivableAccount,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formState,
                                  "receivableAccount",
                                  $$v
                                )
                              },
                              expression: "formState.receivableAccount"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_operator_name"),
                            prop: "operatorName"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              "data-testid":
                                "trucking-ar-invoice-operator-name",
                              "allow-clear": "",
                              placeholder: _vm.$t("common.insert-text", {
                                text: _vm.$t("lbl_operator_name")
                              })
                            },
                            model: {
                              value: _vm.formState.operatorName,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "operatorName", $$v)
                              },
                              expression: "formState.operatorName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_assignee"),
                            prop: "assignee"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              "show-search": "",
                              loading: _vm.loading.assignee,
                              "data-testid": "trucking-ar-invoice-assignee",
                              "filter-option": false,
                              options: _vm.assigneeOptions,
                              "dropdown-match-select-width": false,
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_assignee")
                              }),
                              "label-in-value": ""
                            },
                            on: { search: _vm.onSearchAssignee },
                            model: {
                              value: _vm.formState.assignee,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "assignee", $$v)
                              },
                              expression: "formState.assignee"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_invoice_description"),
                            prop: "description"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "extra",
                              fn: function() {
                                return [
                                  _c("character-length", {
                                    attrs: { value: _vm.formState.description }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("a-textarea", {
                            attrs: {
                              "allow-clear": "",
                              "data-testid": "trucking-ar-invoice-description",
                              placeholder: _vm.$t("common.insert-text", {
                                text: _vm.$t("lbl_invoice_description")
                              }),
                              rows: 4
                            },
                            model: {
                              value: _vm.formState.description,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "description", $$v)
                              },
                              expression: "formState.description"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.formState.status
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_invoice_status"),
                                prop: "status"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ant-form-text",
                                  attrs: {
                                    "data-testid": "trucking-ar-invoice-status"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.formState.status))]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.formState.journalId
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_journal_number"),
                                prop: "journalId"
                              }
                            },
                            [
                              _c(
                                "RouterLink",
                                {
                                  staticClass: "text-link",
                                  attrs: {
                                    to: {
                                      name: "generaljournal.detail",
                                      params: { id: _vm.formState.journalId }
                                    },
                                    "data-testid": "trucking-ar-invoice-journal"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formState.journalNumber) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-tabs",
                { staticClass: "my-3", attrs: { type: "card" } },
                [
                  _c(
                    "a-tab-pane",
                    { key: "unit", attrs: { tab: _vm.$t("lbl_unit_code") } },
                    [
                      _c("a-table", {
                        attrs: {
                          "data-source": _vm.formState.products,
                          columns: _vm.columns,
                          size: "small",
                          loading: _vm.loading.generate,
                          "data-testid": "trucking-ar-invoice-table",
                          pagination: false,
                          scroll: { x: 3500 },
                          "row-selection": {
                            selectedRowKeys: _vm.selectedRowKeys,
                            onChange: _vm.onSelectChange
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "a-space",
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          "data-testid":
                                            "trucking-ar-invoice-add-row",
                                          type: "primary"
                                        },
                                        on: { click: _vm.addRow }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("lbl_add_row")) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          "data-testid":
                                            "trucking-ar-invoice-delete-row",
                                          type: "danger",
                                          disabled: !_vm.selectedRowKeys.length
                                        },
                                        on: { click: _vm.deleteRow }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("lbl_delete_row")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "unitCode",
                            fn: function(text, record) {
                              return [
                                record.generated
                                  ? _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-testid":
                                            "trucking-ar-invoice-product-unit-code"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(record.unitCode.label) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _c("a-select", {
                                      attrs: {
                                        "label-in-value": "",
                                        options: record.isSearchUnitCode
                                          ? record.unitCodeOptions
                                          : _vm.unitCodeOptions,
                                        loading: record.loadingUnitCode,
                                        "show-search": "",
                                        "filter-option": false,
                                        "dropdown-match-select-width": false,
                                        "data-testid":
                                          "trucking-ar-invoice-product-unit-code",
                                        placeholder: _vm.$t(
                                          "common.select-text",
                                          { text: _vm.$t("lbl_unit_code") }
                                        )
                                      },
                                      on: {
                                        search: function($event) {
                                          return _vm.onSearchUnitCode(
                                            record,
                                            $event
                                          )
                                        }
                                      },
                                      model: {
                                        value: record.unitCode,
                                        callback: function($$v) {
                                          _vm.$set(record, "unitCode", $$v)
                                        },
                                        expression: "record.unitCode"
                                      }
                                    })
                              ]
                            }
                          },
                          {
                            key: "shipmentId",
                            fn: function(text, record) {
                              return [
                                record.generated
                                  ? _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-testid":
                                            "trucking-ar-invoice-product-shipment-id"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(record.shipmentId || "-") +
                                            " "
                                        )
                                      ]
                                    )
                                  : _c("a-input", {
                                      attrs: {
                                        "data-testid":
                                          "trucking-ar-invoice-product-shipment-id",
                                        "allow-clear": "",
                                        placeholder: _vm.$t(
                                          "common.insert-text",
                                          {
                                            text: _vm.$t("lbl_shipment_id")
                                          }
                                        )
                                      },
                                      model: {
                                        value: record.shipmentId,
                                        callback: function($$v) {
                                          _vm.$set(record, "shipmentId", $$v)
                                        },
                                        expression: "record.shipmentId"
                                      }
                                    })
                              ]
                            }
                          },
                          {
                            key: "salesPerson",
                            fn: function(text, record) {
                              return [
                                record.generated
                                  ? _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-testid":
                                            "trucking-ar-invoice-product-sales-person"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              record.salesPerson.label || "-"
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _c("a-select", {
                                      attrs: {
                                        options: record.isSearchSalesPerson
                                          ? record.salesPersonOptions
                                          : _vm.salesPersonOptions,
                                        loading: record.loadingSalesPerson,
                                        "data-testid":
                                          "trucking-ar-invoice-product-sales-person",
                                        "allow-clear": "",
                                        "label-in-value": "",
                                        placeholder: _vm.$t(
                                          "common.select-text",
                                          { text: _vm.$t("lbl_sales_name") }
                                        ),
                                        "show-search": "",
                                        "filter-option": false,
                                        "dropdown-match-select-width": false
                                      },
                                      on: {
                                        search: function($event) {
                                          return _vm.onSearchSalesPerson(
                                            record,
                                            $event
                                          )
                                        }
                                      },
                                      model: {
                                        value: record.salesPerson,
                                        callback: function($$v) {
                                          _vm.$set(record, "salesPerson", $$v)
                                        },
                                        expression: "record.salesPerson"
                                      }
                                    })
                              ]
                            }
                          },
                          {
                            key: "price",
                            fn: function(text, record) {
                              return [
                                _c("a-input-number", {
                                  staticClass: "w-100",
                                  attrs: {
                                    placeholder: _vm.$t("common.insert-text", {
                                      text: _vm.$t("lbl_price")
                                    }),
                                    parser: _vm.reverseFormatNumber,
                                    formatter: _vm.formatterNumber,
                                    min: 0,
                                    precision: _vm.storeBaseDecimalPlace,
                                    "data-testid":
                                      "trucking-ar-invoice-product-price"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.onChangePrice(record, $event)
                                    }
                                  },
                                  model: {
                                    value: record.price,
                                    callback: function($$v) {
                                      _vm.$set(record, "price", $$v)
                                    },
                                    expression: "record.price"
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "revenueAccount",
                            fn: function(text, record) {
                              return [
                                _c("a-select", {
                                  attrs: {
                                    options: record.isSearchRevenueAccount
                                      ? record.revenueAccountOptions
                                      : _vm.revenueAccountOptions,
                                    loading: record.loadingRevenueAccount,
                                    "show-search": "",
                                    "allow-clear": "",
                                    "label-in-value": "",
                                    "filter-option": false,
                                    "dropdown-match-select-width": false,
                                    placeholder: _vm.$t("common.select-text", {
                                      text: _vm.$t("lbl_revenue_account")
                                    }),
                                    "data-testid":
                                      "trucking-ar-invoice-product-revenue-account"
                                  },
                                  on: {
                                    search: function($event) {
                                      return _vm.onSearchRevenueAccount(
                                        record,
                                        $event
                                      )
                                    }
                                  },
                                  model: {
                                    value: record.revenueAccount,
                                    callback: function($$v) {
                                      _vm.$set(record, "revenueAccount", $$v)
                                    },
                                    expression: "record.revenueAccount"
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "includePph",
                            fn: function(text, record) {
                              return [
                                _c("a-switch", {
                                  attrs: {
                                    "data-testid":
                                      "trucking-ar-invoice-product-include-pph",
                                    disabled: record.disabledTax
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.onChangeIncludePph(record)
                                    }
                                  },
                                  model: {
                                    value: record.includePph,
                                    callback: function($$v) {
                                      _vm.$set(record, "includePph", $$v)
                                    },
                                    expression: "record.includePph"
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "incomeTax",
                            fn: function(text, record) {
                              return [
                                _c("a-select", {
                                  attrs: {
                                    options: record.isSearchIncomeTax
                                      ? record.incomeTaxOptions
                                      : _vm.incomeTaxOptions,
                                    loading: record.loadingIncomeTax,
                                    "allow-clear": "",
                                    disabled:
                                      !record.includePph || record.disabledTax,
                                    "show-search": "",
                                    "label-in-value": "",
                                    "filter-option": false,
                                    "dropdown-match-select-width": false,
                                    placeholder: _vm.$t("common.select-text", {
                                      text: _vm.$t("lbl_income_tax")
                                    }),
                                    "data-testid":
                                      "trucking-ar-invoice-product-income-tax"
                                  },
                                  on: {
                                    search: function($event) {
                                      return _vm.onSearchIncomeTax(
                                        record,
                                        $event
                                      )
                                    }
                                  },
                                  model: {
                                    value: record.incomeTax,
                                    callback: function($$v) {
                                      _vm.$set(record, "incomeTax", $$v)
                                    },
                                    expression: "record.incomeTax"
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "taxCode",
                            fn: function(text, record) {
                              return [
                                _c("a-select", {
                                  attrs: {
                                    options: record.isSearchTaxCode
                                      ? record.taxCodeOptions
                                      : _vm.taxCodeOptions,
                                    loading: record.loadingTaxCode,
                                    "show-search": "",
                                    "label-in-value": "",
                                    "filter-option": false,
                                    "dropdown-match-select-width": false,
                                    placeholder: _vm.$t("common.select-text", {
                                      text: _vm.$t("lbl_tax_code")
                                    }),
                                    disabled: record.disabledTax,
                                    "data-testid":
                                      "trucking-ar-invoice-product-tax-code"
                                  },
                                  on: {
                                    search: function($event) {
                                      return _vm.onSearchTaxCode(record, $event)
                                    },
                                    change: function($event) {
                                      return _vm.onChangeTaxCode(record, $event)
                                    }
                                  },
                                  model: {
                                    value: record.taxCode,
                                    callback: function($$v) {
                                      _vm.$set(record, "taxCode", $$v)
                                    },
                                    expression: "record.taxCode"
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "description",
                            fn: function(text, record) {
                              return [
                                _c("a-textarea", {
                                  attrs: {
                                    autosize: "",
                                    "allow-clear": "",
                                    placeholder: _vm.$t("common.insert-text", {
                                      text: _vm.$t("lbl_description")
                                    }),
                                    "data-testid":
                                      "trucking-ar-invoice-product-description"
                                  },
                                  model: {
                                    value: record.description,
                                    callback: function($$v) {
                                      _vm.$set(record, "description", $$v)
                                    },
                                    expression: "record.description"
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "more",
                            fn: function(text, record) {
                              return [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      "data-testid":
                                        "trucking-ar-invoice-product-more",
                                      type: "link"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleViewDiscount(record)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_more")) + " "
                                    )
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: "footer",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-right",
                                    attrs: {
                                      "data-testid":
                                        "trucking-ar-invoice-product-total-item"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("lbl_total_items", {
                                            total: _vm.formState.products.length
                                          })
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    {
                      key: "tax",
                      attrs: { "force-render": "", tab: _vm.$t("lbl_tax") }
                    },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_tax_type"),
                            prop: "customerTaxType"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              placeholder: _vm.$t("common.insert-text", {
                                text: _vm.$t("lbl_customer_tax_type")
                              }),
                              loading: _vm.loading.customerTaxType,
                              options: _vm.customerTaxTypeOptions,
                              "show-search": "",
                              "filter-option": false,
                              "data-testid":
                                "trucking-ar-invoice-tax-customer-tax-type"
                            },
                            on: {
                              search: _vm.onSearchCustomerTaxType,
                              change: _vm.onChangeCustomerTaxType
                            },
                            model: {
                              value: _vm.formState.customerTaxType,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "customerTaxType", $$v)
                              },
                              expression: "formState.customerTaxType"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_tax_registration_number"),
                            prop: "taxRegistrationNumber"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              "allow-clear": "",
                              "data-testid":
                                "trucking-ar-invoice-tax-registration-number",
                              placeholder: _vm.$t("common.insert-text", {
                                text: _vm.$t("lbl_tax_registration_number")
                              })
                            },
                            model: {
                              value: _vm.formState.taxRegistrationNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formState,
                                  "taxRegistrationNumber",
                                  $$v
                                )
                              },
                              expression: "formState.taxRegistrationNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_tax_registration_name"),
                            prop: "taxRegistrationName"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              "data-testid":
                                "trucking-ar-invoice-tax-registration-name",
                              "allow-clear": "",
                              placeholder: _vm.$t("common.insert-text", {
                                text: _vm.$t("lbl_tax_registration_name")
                              })
                            },
                            model: {
                              value: _vm.formState.taxRegistrationName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formState,
                                  "taxRegistrationName",
                                  $$v
                                )
                              },
                              expression: "formState.taxRegistrationName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_tax_invoice_uploaded"),
                            prop: "taxIsUploaded"
                          }
                        },
                        [
                          _c("a-switch", {
                            attrs: {
                              "data-testid":
                                "trucking-ar-invoice-tax-is-uploaded"
                            },
                            on: { change: _vm.onChangeTaxUpload },
                            model: {
                              value: _vm.formState.taxIsUploaded,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "taxIsUploaded", $$v)
                              },
                              expression: "formState.taxIsUploaded"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_tax_invoice_date"),
                            prop: "taxInvoiceDate"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              format: _vm.DEFAULT_DATE_FORMAT,
                              "data-testid":
                                "trucking-ar-invoice-tax-invoice-date",
                              disabled: ""
                            },
                            model: {
                              value: _vm.formState.taxInvoiceDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "taxInvoiceDate", $$v)
                              },
                              expression: "formState.taxInvoiceDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_tax_invoice_number"),
                            prop: "taxInvoiceNumber"
                          }
                        },
                        [
                          _c(
                            "a-skeleton",
                            {
                              attrs: {
                                active: "",
                                title: false,
                                paragraph: { rows: 1 },
                                loading: _vm.loading.taxInvoiceNumber
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: {
                                  "data-testid":
                                    "trucking-ar-invoice-tax-invoice-number",
                                  disabled: ""
                                },
                                model: {
                                  value: _vm.formState.taxInvoiceNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formState,
                                      "taxInvoiceNumber",
                                      $$v
                                    )
                                  },
                                  expression: "formState.taxInvoiceNumber"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    {
                      key: "prepayment",
                      attrs: { tab: _vm.$t("lbl_prepayment") }
                    },
                    [
                      _c(
                        "a-row",
                        { attrs: { type: "flex", gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c("a-table", {
                                attrs: {
                                  columns: _vm.prepaymentColumns,
                                  "data-source":
                                    _vm.formState.prepayment.prepaymentLines,
                                  pagination: false,
                                  "row-key": "invoicePrepaymentId",
                                  size: "small",
                                  "row-class-name": function(_record, index) {
                                    return index % 2
                                      ? "bg-white"
                                      : "bg-gray-light"
                                  },
                                  "data-testid":
                                    "trucking-ar-invoice-prepayment-used-table"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "title",
                                    fn: function() {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-testid":
                                                "trucking-ar-invoice-prepayment-used-total-used-prepayment"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "lbl_prepayment_used_amout"
                                                  )
                                                ) +
                                                ": " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm.totalUsedPrepayment
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "invoiceNumber",
                                    fn: function(text, record) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-testid":
                                                "trucking-ar-invoice-prepayment-used-invoice-prepayment-number"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  record.invoicePrepaymentNumber
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "appliedAmount",
                                    fn: function(text, record) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-testid":
                                                "trucking-ar-invoice-prepayment-used-applied-amount"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    record.appliedAmount
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "action",
                                    fn: function(text, record) {
                                      return [
                                        _c("a-button", {
                                          attrs: {
                                            type: "danger",
                                            shape: "circle",
                                            icon: "delete",
                                            size: "small",
                                            "data-testid":
                                              "trucking-ar-invoice-prepayment-used-delete"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleDeleteUsedPrepayment(
                                                record
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "footer",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "text-right",
                                            attrs: {
                                              "data-testid":
                                                "trucking-ar-invoice-prepayment-used-total-item"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("lbl_total_items", {
                                                    total:
                                                      _vm.formState.prepayment
                                                        .prepaymentLines.length
                                                  })
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c("a-table", {
                                attrs: {
                                  columns: _vm.prepaymentColumns,
                                  "data-source": _vm.dtPrepayment.data,
                                  pagination: {
                                    showTotal: function() {
                                      return _vm.$t("lbl_total_items", {
                                        total: _vm.dtPrepayment.totalElements
                                      })
                                    },
                                    total: _vm.dtPrepayment.totalElements,
                                    showSizeChanger: true,
                                    pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                                    current: _vm.prepaymentPagination.page,
                                    defaultPageSize:
                                      _vm.prepaymentPagination.limit
                                  },
                                  loading: _vm.loading.prepayment,
                                  "row-key": "id",
                                  size: "small",
                                  "row-class-name": function(_record, index) {
                                    return index % 2
                                      ? "bg-white"
                                      : "bg-gray-light"
                                  },
                                  "data-testid":
                                    "trucking-ar-invoice-prepayment-table"
                                },
                                on: { change: _vm.onChangePrepaymentTable },
                                scopedSlots: _vm._u([
                                  {
                                    key: "title",
                                    fn: function() {
                                      return [
                                        _c(
                                          "a-row",
                                          {
                                            attrs: {
                                              type: "flex",
                                              justify: "space-between"
                                            }
                                          },
                                          [
                                            _c("a-col", [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "lbl_customer_prepayment"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]),
                                            _c(
                                              "a-col",
                                              [
                                                _c(
                                                  "a-button",
                                                  {
                                                    attrs: {
                                                      icon: "reload",
                                                      size: "small",
                                                      loading:
                                                        _vm.loading.prepayment,
                                                      "data-testid":
                                                        "trucking-ar-invoice-prepayment-refresh"
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.handleRefreshPrepayment
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("lbl_refresh")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "invoiceNumber",
                                    fn: function(text, record) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-testid":
                                                "trucking-ar-invoice-prepayment-invoice-number"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(record.invoiceNumber) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "appliedAmount",
                                    fn: function(text, record) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-testid":
                                                "trucking-ar-invoice-prepayment-gross-amount"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    record.grossAmount
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "action",
                                    fn: function(text, record) {
                                      return [
                                        _vm.isPrepaymentUsed(record)
                                          ? _c("a-icon", {
                                              staticStyle: {
                                                "font-size": "20px"
                                              },
                                              attrs: {
                                                type: "check-circle",
                                                theme: "twoTone",
                                                "two-tone-color": "#52c41a",
                                                "data-testid":
                                                  "trucking-ar-invoice-prepayment-is-used"
                                              }
                                            })
                                          : _c(
                                              "a-button",
                                              {
                                                attrs: {
                                                  type: "primary",
                                                  "data-testid":
                                                    "trucking-ar-invoice-prepayment-use",
                                                  size: "small"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.handleUsePrepayment(
                                                      record
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("lbl_use")) +
                                                    " "
                                                )
                                              ]
                                            )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  !!_vm.invoiceId
                    ? _c(
                        "a-tab-pane",
                        { key: "status", attrs: { tab: _vm.$t("lbl_status") } },
                        [
                          _c(
                            "a-row",
                            { attrs: { type: "flex" } },
                            [
                              _c(
                                "a-col",
                                { attrs: { lg: 12, xs: 24 } },
                                [
                                  _c(
                                    "a-descriptions",
                                    {
                                      attrs: {
                                        column: 1,
                                        bordered: "",
                                        size: "small"
                                      }
                                    },
                                    [
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_total"),
                                            "data-testid":
                                              "trucking-ar-invoice-status-total"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.formState.statusDetail
                                                    .total
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_prepayment_used")
                                          }
                                        },
                                        [
                                          _c(
                                            "a-row",
                                            {
                                              attrs: {
                                                type: "flex",
                                                justify: "space-between"
                                              }
                                            },
                                            [
                                              _c("a-col", [
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      "data-testid":
                                                        "trucking-ar-invoice-status-prepayment-used"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("currency")(
                                                            _vm.formState
                                                              .statusDetail
                                                              .prepaymentUsed
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _c("a-col", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "text-link",
                                                    attrs: {
                                                      "data-testid":
                                                        "trucking-ar-invoice-status-view-prepayment"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.handleStatusDetailView(
                                                          "prepayment"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("lbl_view")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_remaining_invoice_amount"
                                            ),
                                            "data-testid":
                                              "trucking-ar-invoice-status-remaining-invoice-amount"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.formState.statusDetail
                                                    .remainingInvoiceAmount
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_credit_memo")
                                          }
                                        },
                                        [
                                          _c(
                                            "a-row",
                                            {
                                              attrs: {
                                                type: "flex",
                                                justify: "space-between"
                                              }
                                            },
                                            [
                                              _c("a-col", [
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      "data-testid":
                                                        "trucking-ar-invoice-status-credit-memo-used"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("currency")(
                                                            _vm.formState
                                                              .statusDetail
                                                              .creditMemoUsed
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _c("a-col", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "text-link",
                                                    attrs: {
                                                      "data-testid":
                                                        "trucking-ar-invoice-status-view-credit-memo"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.handleStatusDetailView(
                                                          "creditMemo"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t("lbl_view")
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_return_amount"),
                                            "data-testid":
                                              "trucking-ar-invoice-status-return-amount"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.formState.statusDetail
                                                    .returnAmount
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_receivable_account"
                                            ),
                                            "data-testid":
                                              "trucking-ar-invoice-status-receivable-account"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formState.statusDetail
                                                  .receivableAccount
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_paid_status")
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-link",
                                              attrs: {
                                                "data-testid":
                                                  "trucking-ar-invoice-status-paid-status-view"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleStatusDetailView(
                                                    "paidStatus"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("lbl_view")) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a-descriptions-item",
                                        {
                                          attrs: {
                                            label: _vm.$t("lbl_join_invoice"),
                                            "data-testid":
                                              "trucking-ar-invoice-status-invoice-join-no"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.formState.statusDetail
                                                  .invoiceJoinNo || "-"
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("a-divider"),
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { align: "end", span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_total"),
                            prop: "totalGross"
                          }
                        },
                        [
                          _c("a-input-number", {
                            staticClass: "w-100",
                            attrs: {
                              formatter: _vm.formatterNumber,
                              parser: _vm.reverseFormatNumber,
                              min: 0,
                              disabled: "",
                              precision: _vm.storeBaseDecimalPlace,
                              "data-testid": "trucking-ar-invoice-total-gross"
                            },
                            model: {
                              value: _vm.formState.totalGross,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "totalGross", $$v)
                              },
                              expression: "formState.totalGross"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_discount_amount") } },
                        [
                          _c(
                            "a-row",
                            { attrs: { type: "flex", gutter: [16, 16] } },
                            [
                              _c(
                                "a-col",
                                { attrs: { flex: "1" } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticClass: "mb-0",
                                      attrs: { prop: "discountAmount" }
                                    },
                                    [
                                      _c("a-input-number", {
                                        staticClass: "w-100",
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "common.insert-text",
                                            {
                                              text: _vm.$t(
                                                "lbl_discount_amount"
                                              )
                                            }
                                          ),
                                          formatter: _vm.formatterNumber,
                                          parser: _vm.reverseFormatNumber,
                                          min: 0,
                                          precision: _vm.storeBaseDecimalPlace,
                                          "data-testid":
                                            "trucking-ar-invoice-discount-amount"
                                        },
                                        on: {
                                          change: _vm.onChangeAdditionalDiscount
                                        },
                                        model: {
                                          value: _vm.formState.discountAmount,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formState,
                                              "discountAmount",
                                              $$v
                                            )
                                          },
                                          expression: "formState.discountAmount"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { flex: "1" } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      staticClass: "mb-0",
                                      attrs: { prop: "discountPercent" }
                                    },
                                    [
                                      _c("a-input-number", {
                                        staticClass: "w-100",
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "common.insert-text",
                                            {
                                              text: _vm.$t(
                                                "lbl_discount_percent"
                                              )
                                            }
                                          ),
                                          formatter: _vm.formatterPercent,
                                          parser: _vm.reverseFormatPercent,
                                          min: 0,
                                          precision: _vm.storeBaseDecimalPlace,
                                          "data-testid":
                                            "trucking-ar-invoice-discount-percent"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.onChangeAdditionalDiscount(
                                              $event,
                                              true
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.formState.discountPercent,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formState,
                                              "discountPercent",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formState.discountPercent"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_total_tax"),
                            prop: "totalTax"
                          }
                        },
                        [
                          _c("a-input-number", {
                            staticClass: "w-100",
                            attrs: {
                              formatter: _vm.formatterNumber,
                              parser: _vm.reverseFormatNumber,
                              min: 0,
                              disabled: "",
                              precision: _vm.storeBaseDecimalPlace,
                              "data-testid": "trucking-ar-invoice-total-tax"
                            },
                            model: {
                              value: _vm.formState.totalTax,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "totalTax", $$v)
                              },
                              expression: "formState.totalTax"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_total_prepayment"),
                            prop: "totalUsedPrepayment"
                          }
                        },
                        [
                          _c("a-input-number", {
                            staticClass: "w-100",
                            attrs: {
                              formatter: _vm.formatterNumber,
                              parser: _vm.reverseFormatNumber,
                              min: 0,
                              disabled: "",
                              precision: _vm.storeBaseDecimalPlace,
                              "data-testid":
                                "trucking-ar-invoice-total-used-prepayment"
                            },
                            model: {
                              value: _vm.formState.totalUsedPrepayment,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formState,
                                  "totalUsedPrepayment",
                                  $$v
                                )
                              },
                              expression: "formState.totalUsedPrepayment"
                            }
                          })
                        ],
                        1
                      ),
                      _c("a-divider"),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_grand_total"),
                            prop: "grandTotal"
                          }
                        },
                        [
                          _c("a-input-number", {
                            staticClass: "w-100",
                            attrs: {
                              formatter: _vm.formatterNumber,
                              parser: _vm.reverseFormatNumber,
                              min: 0,
                              disabled: "",
                              precision: _vm.storeBaseDecimalPlace,
                              "data-testid": "trucking-ar-invoice-grand-total"
                            },
                            model: {
                              value: _vm.formState.grandTotal,
                              callback: function($$v) {
                                _vm.$set(_vm.formState, "grandTotal", $$v)
                              },
                              expression: "formState.grandTotal"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { type: "flex", gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24, align: "end" } },
                    [
                      _c(
                        "a-space",
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                "data-testid": "trucking-ar-invoice-back"
                              },
                              on: { click: _vm.handleBack }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")]
                          ),
                          _vm.allowCancel &&
                          _vm.$can("cancel", "trucking-invoice-ar")
                            ? [
                                _c(
                                  "a-popconfirm",
                                  {
                                    attrs: {
                                      title: _vm.$t("pop_confirmation"),
                                      "ok-text": _vm.$t("lbl_yes"),
                                      "cancel-text": _vm.$t("lbl_no")
                                    },
                                    on: { confirm: _vm.handleCancelInvoice }
                                  },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          "data-testid":
                                            "trucking-ar-invoice-cancel",
                                          type: "danger",
                                          "html-type": "button",
                                          loading: _vm.loading.cancel
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("lbl_cancel")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm._e(),
                          _vm.allowPostJournal &&
                          _vm.$can("update", "trucking-invoice-ar")
                            ? [
                                _c(
                                  "a-popconfirm",
                                  {
                                    attrs: {
                                      title: _vm.$t("pop_confirmation"),
                                      "ok-text": _vm.$t("lbl_yes"),
                                      "cancel-text": _vm.$t("lbl_no")
                                    },
                                    on: { confirm: _vm.handlePostJournal }
                                  },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          "data-testid":
                                            "trucking-ar-invoice-post-journal",
                                          type: "primary",
                                          "html-type": "button",
                                          loading: _vm.loading.postJournal
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("lbl_post_journal")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm._e(),
                          _vm.allowPrint &&
                          _vm.$can("print", "trucking-invoice-ar")
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    "html-type": "button",
                                    "data-testid": "trucking-ar-invoice-print"
                                  },
                                  on: { click: _vm.handlePrint }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_print")) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.allowUpdate &&
                          _vm.$can("update", "trucking-invoice-ar")
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loading.update,
                                    "html-type": "button",
                                    "data-testid": "trucking-ar-invoice-update"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.validateForm("update")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_update")) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.allowSubmit &&
                          (_vm.$can("update", "trucking-invoice-ar") ||
                            _vm.$can("create", "trucking-invoice-ar"))
                            ? _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.loading.submit,
                                    "html-type": "button",
                                    "data-testid": "trucking-ar-invoice-submit"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.validateForm("submit")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_submit")) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.allowReject &&
                          _vm.$can("approve", "trucking-invoice-ar")
                            ? [
                                _c(
                                  "a-popconfirm",
                                  {
                                    attrs: {
                                      title: _vm.$t("pop_confirmation"),
                                      "ok-text": _vm.$t("lbl_yes"),
                                      "cancel-text": _vm.$t("lbl_no")
                                    },
                                    on: { confirm: _vm.handleRejectInvoice }
                                  },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          type: "danger",
                                          "data-testid":
                                            "trucking-ar-invoice-reject",
                                          loading: _vm.loading.reject
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("lbl_reject")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.modalStatus.title,
            width: "50%",
            "destroy-on-close": ""
          },
          on: { cancel: _vm.handleCloseInvoiceStatusModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "a-button",
                    { on: { click: _vm.handleCloseInvoiceStatusModal } },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")]
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.modalStatus.show,
            callback: function($$v) {
              _vm.$set(_vm.modalStatus, "show", $$v)
            },
            expression: "modalStatus.show"
          }
        },
        [
          _c("FormTruckingInvoiceStatusView", {
            attrs: { data: _vm.modalStatus.data }
          })
        ],
        1
      ),
      _c("ModalTruckingInvoiceDiscount", {
        attrs: { data: _vm.modalDiscount.data },
        on: { submit: _vm.handleAddDiscount },
        model: {
          value: _vm.modalDiscount.show,
          callback: function($$v) {
            _vm.$set(_vm.modalDiscount, "show", $$v)
          },
          expression: "modalDiscount.show"
        }
      }),
      _c("InvoicePrintModal", {
        attrs: { "doc-id": _vm.invoiceId },
        model: {
          value: _vm.modalPrint.show,
          callback: function($$v) {
            _vm.$set(_vm.modalPrint, "show", $$v)
          },
          expression: "modalPrint.show"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }