



































































































































import { TruckingAccountReceivableFormDiscountState } from "@/models/interface/trucking-account-receivable";
import { TruckingAccountReceivableUtils } from "@/utils";
import {
  formatterNumber,
  formatterPercent,
  reverseFormatNumber,
  reverseFormatPercent,
} from "@/validator/globalvalidator";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "ModalTruckingInvoiceDiscount",
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    data: {
      required: true,
      type: Object as () => TruckingAccountReceivableFormDiscountState,
    },
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
  data() {
    return {
      formState: {
        discountAmount: 0,
        discountPercent: 0,
      },
    };
  },
  watch: {
    data: {
      handler(value: TruckingAccountReceivableFormDiscountState) {
        this.formState.discountAmount = value.discountAmount;
        this.formState.discountPercent = value.discountPercent;
      },
    },
  },
  methods: {
    formatterPercent,
    reverseFormatPercent,
    reverseFormatNumber,
    formatterNumber,
    handleSave() {
      this.$emit("submit", { formState: this.formState, key: this.data.key });
      this.handleClose();
    },
    handleClose() {
      this.$emit("input", false);
      this.$emit("close");
    },
    onChangeDiscount(key: "amount" | "percent", value: number) {
      const { amount, percent } = TruckingAccountReceivableUtils.countDiscount(
        value,
        this.data.gross,
        key === "percent"
      );
      this.formState.discountAmount = amount;
      this.formState.discountPercent = percent;
    },
  },
});
